const React = require('react');
const { number, bool } = require('prop-types');
const classnames = require('classnames');

const namespace = 'ui-seller-data-status';

const Thermometer = ({ rank, is_large }) => (
  <ul
    aria-hidden="true"
    className={classnames(`${namespace}__thermometer`, { 'thermometer-large': is_large })}
    value={rank}
  >
    <li
      className={classnames('thermometer__level', 'thermometer__level--1', { 'thermometer__level--1-large': is_large })}
    />
    <li
      className={classnames('thermometer__level', 'thermometer__level--2', { 'thermometer__level--2-large': is_large })}
    />
    <li
      className={classnames('thermometer__level', 'thermometer__level--3', { 'thermometer__level--3-large': is_large })}
    />
    <li
      className={classnames('thermometer__level', 'thermometer__level--4', { 'thermometer__level--4-large': is_large })}
    />
    <li
      className={classnames('thermometer__level', 'thermometer__level--5', { 'thermometer__level--5-large': is_large })}
    />
  </ul>
);

Thermometer.propTypes = {
  rank: number.isRequired,
  is_large: bool,
};

Thermometer.defaultProps = {
  is_large: false,
};

module.exports = Thermometer;
