const React = require('react');
const { shape, arrayOf, number, bool } = require('prop-types');
const { Icon } = require('@vpp-frontend-components/icon');
const { StyledLabel } = require('@vpp-frontend-components/styled-label');
const Thermometer = require('./thermometer');
const Info = require('./info');
const { generateKeyToComponent } = require('../../../../utils/validators');

const namespace = 'ui-seller-data-status';

const Status = ({ seller_status_info, rank, info, default_info, is_thermometer_large }) => {
  const { icon, title, subtitle } = seller_status_info ?? {};

  return (
    <div className={`${namespace}__container`}>
      {seller_status_info && (
        <div className={`${namespace}__thermometer-container`}>
          {icon && <Icon id={icon.id} className={`${namespace}__icon-medal`} />}
          {(title || subtitle) && (
            <div className={`${namespace}__lider-seller`}>
              {title && (
                <StyledLabel
                  as="p"
                  text={title.text}
                  color={title.color}
                  font_size={title.font_size}
                  font_family={title.font_family}
                  className={`${namespace}__title`}
                />
              )}
              {subtitle && (
                <StyledLabel
                  as="p"
                  text={subtitle.text}
                  color={subtitle.color}
                  font_size={subtitle.font_size}
                  font_family={subtitle.font_family}
                  className={`${namespace}__subtitle`}
                />
              )}
            </div>
          )}
          {!is_thermometer_large && <Thermometer rank={rank} is_large={is_thermometer_large} />}
        </div>
      )}
      {(is_thermometer_large || !seller_status_info) && (
        <Thermometer rank={rank} is_large={is_thermometer_large || !seller_status_info} />
      )}
      {default_info && (
        <StyledLabel
          as="p"
          text={default_info.text}
          color={default_info.color}
          font_size={default_info.font_size}
          className={`${namespace}__default-info`}
        />
      )}
      {info && (
        <div className={`${namespace}__info-container`}>
          {info.map(infoItem => (
            <Info key={generateKeyToComponent(info)} {...infoItem} />
          ))}
        </div>
      )}
    </div>
  );
};

Status.propTypes = {
  seller_status_info: shape({
    icon: shape({}),
    title: shape({}),
    subtitle: shape({}),
  }),
  rank: number.isRequired,
  info: arrayOf(shape({})),
  default_info: shape({}),
  is_thermometer_large: bool,
};

Status.defaultProps = {
  seller_status_info: null,
  info: null,
  default_info: null,
  is_thermometer_large: false,
};

module.exports = Status;
